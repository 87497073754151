import jwt_decode from 'jwt-decode'
import { get } from 'svelte/store'
import { goto } from '$app/navigation'

import { isAuthenticated, idToken, accessToken, authenticatedUserId } from '_auth/stores/auth.store'
import { end_session_url } from '_auth/stores/provider.store'
import { login_url } from '_auth/stores/provider.store'

export function handleHashChange(hash: string) {
    const params = new URLSearchParams(hash.substring(1))

    let id_token = params.get('id_token')
    let access_token = params.get('access_token')

    if (!id_token || !access_token) {
        id_token = localStorage.getItem('id_token')
        access_token = localStorage.getItem('access_token')
    }

    if (!id_token || !access_token) {
        return
    }

    history.replaceState({}, '', window.location.href.split('#id_token')[0])

    localStorage.setItem('id_token', id_token)
    localStorage.setItem('access_token', access_token)

    return handleToken(id_token, access_token, '')
}

export function handleToken(id_token: string, access_token: string, authorize_token_url: string = '') {
    let decodedToken = jwt_decode<any>(access_token)

    if (!decodedToken) {
        localStorage.removeItem('id_token')
        localStorage.removeItem('access_token')
        logout()
        return false
    }

    let user_id: string = decodedToken.sub
    let expiry_time: number = decodedToken.exp * 1000

    if (expiry_time < Date.now() + 10000) {
        localStorage.removeItem('id_token')
        localStorage.removeItem('access_token')

        return false
    }

    localStorage.setItem('id_token', id_token)
    localStorage.setItem('access_token', access_token)

    authenticatedUserId.set(user_id)

    idToken.set(id_token)
    accessToken.set(access_token)

    isAuthenticated.set(true)

    return true
}

export function logout() {
    localStorage.removeItem('id_token')
    localStorage.removeItem('access_token')
    localStorage.removeItem('after_logged_in')

    const cookie = getCookieData()
    document.cookie = `${cookie.name}=;path=/;domain=${cookie.domain};expires=Thu, 01 Jan 1970 00:00:01 GMT;`

    let url = get(end_session_url)
    goto(url)
}

export function login() {
    localStorage.setItem('after_action_return_path', location.pathname)
    localStorage.setItem('after_logged_in', 'true')

    setTimeout(() => {
        goto(get(login_url))
    }, 600)
}

export function cookieCheck() {
    const cookie = getCookieData()
    const value = getCookie(cookie.name)
    if (!value) {
        logout()
    }
}

export function getCookie(name: string): string {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
    if (match) return match[2]
    return ''
}

export function getCookieData(name: string = 'isAuthenticated') {
    const cookie = {
        name: name,
        domain: getCookieDomain(),
    }

    if (location.host.includes('localhost')) {
        cookie.name += '_dev'
        cookie.domain = 'localhost'
    }

    if (location.host.includes('.qa.')) {
        cookie.name += '_qa'
    }

    if (location.host.includes('.uat.')) {
        cookie.name += '_uat'
    }

    return cookie
}

export function getCookieDomain() {
    let host = location.host.replace('wwww.', '')
    let domain = host.split('.')
    let out: any = []

    const dotArray = []
    dotArray.push('')

    if (domain.length > 3) {
        domain = domain.splice(1, domain.length)
        out = dotArray.concat(domain)
        return out.join('.')
    }

    if (!host.includes('localhost')) {
        out = dotArray.concat(domain)
        return out.join('.')
    }

    return domain.join('.')
}
